import Cookies from 'js-cookie';
import '../Home.css';
import '../../styles/Bubbles.css';
import authGet from "../../functions/authGet";
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const ClassOverview = () => {
  const userName = Cookies.get('user_name');
  const userId = Cookies.get('user_id'); // Assuming user_id is stored in cookies
  const navigate = useNavigate(); // Hook for navigation
  const [classData, setClassData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const classId = pathSegments[2]; // 'class_id' is the third segment

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const response = await authGet(`${serverUrl}/teacher/${classId}/overview`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setClassData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignments();
  }, []);

  const handleClickAssignment = (classId, assignmentId) => {
    navigate(`/teacher/${classId}/${assignmentId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (<div className="home-content">
    <div>
      <h2>Class Overview for {classId}</h2>
      <p>Students: {classData.total_students}</p>
      <p>Total assignments: {classData.total_assignments}</p>
    </div>

    <h2> Assignments by week: </h2>
    {/*Iterate over weeks */}
    {Object.keys(classData.assignments).map(week => (
    <div key={week} className="bubble">
      <h3>Week {week}:</h3>
      {
        classData.assignments[week].map(assignment => (
            <div key={assignment.assignment_id} className="bubble interactive" onClick={() => handleClickAssignment(classId, assignment.assignment_id)}>
              <h4>#{assignment.assignment_number}: {assignment.title}</h4>
              <p>{assignment.topic}</p>
              <p>{assignment.completed} / {classData.total_students} students completed the assignment.</p>
            </div>
            )
        )
      }

    </div>
    ))}

  </div>);
};

export default ClassOverview;