
import Cookies from 'js-cookie';
import '../Home.css';
import authGet from "../../functions/authGet";
import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL;




const TeacherView = () => {
  const userName = Cookies.get('user_name');
  const userId = Cookies.get('user_id'); // Assuming user_id is stored in cookies
  const [classesData, setClassesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Hook for navigation


  useEffect(() => {
    const fetchTeacherClasses = async () => {
      try {
        const response = await authGet(`${serverUrl}/teacher/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setClassesData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherClasses();
  }, [userId]);

  const handleCreateAssignment = (classId) => {
    navigate(`/create-assignment/${classId}`);
  };

  const handleClassOverview = (classId) => {
    navigate(`/teacher/${classId}/overview`)
  }


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
      <div className="home-content">
        <div>
          <h2 style={{ marginBottom: '15px' }}>Benvenuta/o, {userName}</h2>
        </div>
        <div className="home-instructions">
          <ul>
            <li style={{ marginBottom: '10px' }}>
              Qui vedrai le tue classi.
            </li>
          </ul>
        </div>
        <div className="classes-container">
          {Object.keys(classesData).length === 0 ? (
              <p>No classes found</p>
          ) : (
              Object.keys(classesData).map(classId => (
                  <div key={classId} className="message user"> {/* Apply the .message.user class */}
                    <h3>Class ID: {classId}</h3>
                    <p>Total assignments: {classesData[classId]['total assignments']}</p>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <button onClick={() => handleClassOverview(classId)} className="button">
                        View assignments
                      </button>
                    </div>
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                      <button onClick={() => handleCreateAssignment(classId)} className="button">
                        Create new assignment
                      </button>
                    </div>
                  </div>
              ))
          )}
        </div>
      </div>
  );
};

export default TeacherView;