import Cookies from 'js-cookie';
import '../Home.css';
import '../../styles/Bubbles.css';
import authGet from "../../functions/authGet";
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import ClassOverviewEdit from "./ClassOverviewEdit";
import authPost from "../../functions/authPost";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const AdminClassOverview = () => {
  const userName = Cookies.get('user_name');
  const userId = Cookies.get('user_id');
  const navigate = useNavigate();
  const [classData, setClassData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const classId = pathSegments[2];
  const [editMode, setEditMode] = useState(false);

  // State for teacher management
  const [teachersList, setTeachersList] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);

  useEffect(() => {
    const fetchClassData = async () => {
      try {
        const [assignmentsResponse, peopleResponse, teachersResponse] = await Promise.all([
          authGet(`${serverUrl}/teacher/${classId}/overview`),
          authGet(`${serverUrl}/admin/class_summary/${classId}`),
          authGet(`${serverUrl}/admin/get_teachers`)
        ]);

        if (!assignmentsResponse.ok || !peopleResponse.ok || !teachersResponse.ok) {
          throw new Error('One of the network responses was not ok');
        }

        const assignmentsData = await assignmentsResponse.json();
        const peopleData = await peopleResponse.json();
        const teachersData = await teachersResponse.json();

        // Combine both data sets into one object
        const combinedData = {
          ...assignmentsData,
          ...peopleData
        };

        setClassData(combinedData);
        setTeachersList(teachersData.teachers);

        // Set the selectedTeacherId to the current teacher's ID
        if (combinedData.teachers && combinedData.teachers.length > 0) {
          setSelectedTeacherId(combinedData.teachers[0].id);
        }

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClassData();
  }, [classId]);

  useEffect(() => {
    console.log('classData', classData);
  }, [classData]);

  const handleClickAssignment = (classId, assignmentId) => {
    navigate(`/teacher/${classId}/${assignmentId}`);
  };

  const handleConfirmChanges = async (students) => {
    try {
      // Update students
      const classFields = {
        classId: classId,
        students: students,
      };
      const responseStudents = await authPost(`/admin/update_students`, classFields);

      if (!responseStudents.ok) {
        throw new Error('Failed to update students');
      }

      // Update teacher
      const teacherData = {
        class_id: classId,
        teacher_id: selectedTeacherId,
      };
      const responseTeacher = await authPost(`/admin/edit_class_teacher`, teacherData);

      if (!responseTeacher.ok) {
        throw new Error('Failed to update teacher');
      }

      const resultStudents = await responseStudents.json();
      const resultTeacher = await responseTeacher.json();

      // Handle successful response
      alert(`${resultStudents.message}\n${resultTeacher.message}`);
      window.location.reload();
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleTeacherChange = (event) => {
    setSelectedTeacherId(event.target.value);
  };

  // Function to handle password reset
  const handleResetPassword = async (userId, userType) => {
    try {
      const response = await authPost(`/admin/reset_user_password/${userId}`, {});

      if (!response.ok) {
        throw new Error('Failed to reset password');
      }

      const result = await response.json();

      // Show success message
      alert(`Password was successfully reset and the credentials were sent to the ${userType} via email.`);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (<div className="home-content">
    <div>
      <h2>Class Overview for {classId}</h2>

      {/* TEACHER MANAGEMENT */}
      <p>Teacher</p>
      {!editMode ?
          <table border="1" cellPadding="5">
            <thead>
            <tr>
              <th>Surname</th>
              <th>Name</th>
              <th>Email</th>
              <th>ID</th>
              <th>Group</th>
              <th>Actions</th> {/* Added Actions column */}
            </tr>
            </thead>
            <tbody>
            {classData.teachers
                .sort((a, b) => a.surname.localeCompare(b.surname))
                .map((teacher, index) => (
                    <tr key={index}>
                      <td>{teacher.surname}</td>
                      <td>{teacher.name}</td>
                      <td>{teacher.email}</td>
                      <td>{teacher.id}</td>
                      <td>{teacher.user_group}</td>
                      <td>
                        <button onClick={() => handleResetPassword(teacher.id, 'teacher')}>
                          Reset Password
                        </button>
                      </td>
                    </tr>
                ))}
            </tbody>
          </table>
          :
          <div>
            <label htmlFor="teacherSelect">Select Teacher:</label>
            <select
                id="teacherSelect"
                value={selectedTeacherId || ''}
                onChange={handleTeacherChange}
            >
              <option value="">-- Select a Teacher --</option>
              {teachersList.map((teacher) => (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.surname}, {teacher.name} ({teacher.email})
                  </option>
              ))}
            </select>
          </div>
      }

      {/* STUDENT MANAGEMENT */}
      {!editMode ?
          <div>
            <p>Students: {classData.total_students}</p>
            <table border="1" cellPadding="5">
              <thead>
              <tr>
                <th>Surname</th>
                <th>Name</th>
                <th>Email</th>
                <th>ID</th>
                <th>Group</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {classData.students
                  .sort((a, b) => a.surname.localeCompare(b.surname))
                  .map((student, index) => (
                      <tr key={index}>
                        <td>{student.surname}</td>
                        <td>{student.name}</td>
                        <td>{student.email}</td>
                        <td>{student.id}</td>
                        <td>{student.user_group}</td>
                        <td>
                          <button onClick={() => handleResetPassword(student.id, 'student')}>
                            Reset Password
                          </button>
                        </td>
                      </tr>
                  ))}
              </tbody>
            </table>
          </div> :
          <ClassOverviewEdit classData={classData} setClassData={setClassData}/>
      }

      {/* EDIT / CONFIRM BUTTONS */}
      {!editMode ?
          <button onClick={() => setEditMode(!editMode)}>
            Edit
          </button>
          :
          <button onClick={() => handleConfirmChanges(classData.students)}>
            Confirm Changes
          </button>
      }

      <p>Total assignments: {classData.total_assignments}</p>
    </div>

    <h2>Assignments by week:</h2>
    {/* Iterate over weeks */}
    {Object.keys(classData.assignments).map(week => (
        <div key={week} className="bubble">
          <h3>Week {week}:</h3>
          {
            classData.assignments[week].map(assignment => (
                <div key={assignment.assignment_id} className="bubble interactive"
                     onClick={() => handleClickAssignment(classId, assignment.assignment_id)}>
                  <h4>#{assignment.assignment_number}: {assignment.title}</h4>
                  <p>{assignment.topic}</p>
                  <p>{assignment.completed} / {classData.total_students} students completed the assignment.</p>
                </div>
            ))
          }
        </div>
    ))}
  </div>);
};

export default AdminClassOverview;