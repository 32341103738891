// fields/TitleField.js
import React, { useState } from 'react';

const TitleField = ({ value, onChange, editMode, openModal }) => {
  const handleInfoClick = () => {
    openModal(
      <div>
        <h3>TITLE</h3>
        <p>
          Provide a concise title for the assignment, using 3-5 words.
          <br />
          This is the actual title of the exercise. The students will see it on
          the exercise list they have to do.
          <br />
          Some example can be:
        </p>
        <ul>
          <li>Week #1 exercise 1</li>
          <li>Essay n. 9</li>
          <li>...</li>
        </ul>
      </div>
    );
  };
  return (
    <div className="bubble input">
      <div className="label-container">
        <label>Short Title (max 3-5 words)</label>
        <button type="button" onClick={handleInfoClick}>
          ?
        </button>
      </div>
      {editMode ? (
        <div>
          <textarea
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              // Auto-resize logic
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            placeholder="Title of the assignment (will be seen by the students)"
          />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default TitleField;
