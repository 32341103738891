// fields/DescriptionField.js
import React, { useState } from 'react';

const DescriptionField = ({ value, onChange, editMode, openModal }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClick = () => {
    openModal(
      <div>
        <h3>Description</h3>
        <p>
          The description should represent what the students are asked to do and
          how.
          <br />
          Examples of descriptions can be:
        </p>
        <ul>
          <li>
            In case of an <u>essay</u>:<br />
            <i>
              Students are asked to write a short essay about a contemporary
              social issue, such as AI, social media, climate change… The essay
              should be approx. 200-220-word long.
            </i>
          </li>
          <li>
            In case of <u>sentence correction</u>:<br />
            <i>
              Students are asked to correct the mistakes they will find in each
              sentence.
              <br /> There is just one mistake in each sentence.
            </i>
          </li>
          <li>
            In case of <u>sentence completion</u>:<br />
            <i>
              Students are asked to complete the sentences with the most
              suitable form of the verbs in brackets.
              <br />
              They must understand which tense to use in the zero, first, second
              and third conditional. There may be more than one word.
            </i>
          </li>
        </ul>
      </div>
    );
  };
  return (
    <div className="bubble input">
      <div className="label-container">
        <label>Description</label>
        <button type="button" onClick={handleInfoClick}>
          ?
        </button>
      </div>
      {editMode ? (
        <div>
          <textarea
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              // Auto-resize logic
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            placeholder="Explaination of the task"
          />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default DescriptionField;
