import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

const ExcelUpload = ({ onValidExcel }) => {
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState('');
  const [students, setStudents] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

      const [header, ...rows] = worksheet;
      const expectedColumns = ['name', 'surname', 'email'];

      if (header.length === expectedColumns.length && header.every((col, index) => col.toLowerCase() === expectedColumns[index])) {
        setError(null);
        setStudents(rows.map(([name, surname, email]) => ({ name, surname, email })));
        setFileName(file.name);
        setFileUploaded(true);
      } else {
        setError('Incorrect columns');
        alert('Incorrect columns');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.xlsx, .xls' });

  const handleConfirm = () => {
    onValidExcel(students);
    resetComponent();
  };

  const handleCancel = () => {
    resetComponent();
  };

  const resetComponent = () => {
    setFileName('');
    setStudents([]);
    setFileUploaded(false);
    setError(null);
  };

  return (
      <div>
        {fileUploaded ? (
            <div>
              <p>File: {fileName}</p>
              <h3>Student List</h3>
              <ul>
                {students.map((student, index) => (
                    <li key={index}>{`${student.name} ${student.surname} (${student.email})`}</li>
                ))}
              </ul>
              <button onClick={handleConfirm}>Confirm</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
        ) : (
            <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
              <input {...getInputProps()} />
              <p>Drag & drop an Excel file here, or click to select one</p>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        )}
      </div>
  );
};

export default ExcelUpload;
