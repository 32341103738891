import React from 'react';
import Cookies from 'js-cookie';
import './Home.css';

const Home = () => {
  const userGroup = Cookies.get('user_group');
  const userName = Cookies.get('user_name');

  return (
        <div className="home-content">
          <div>
            <h2 style={{marginBottom: '15px'}}>Benvenuta/o, {userName}</h2>
          </div>
          {userGroup === 'treatment' ? (
              <div>
                <div className="home-instructions">
                  <ul>
                    <li style={{marginBottom: '10px'}}>
                      In questa piattaforma puoi accedere ai compiti per casa di Inglese assegnati dalla tua
                      insegnante.
                    </li>
                    <li style={{marginBottom: '10px'}}>
                      L'obiettivo della piattaforma è testare delle nuove tecnologie per varie forme di apprendimento
                      personalizzato.
                      L'interfaccia e gli esercizi che vedi potrebbero essere diversi per altri tuoi compagni di
                      classe, e questo è normale.
                    </li>
                  </ul>
                  <div><b>ISTRUZIONI:</b></div>
                </div>
                <ul>
                  <li style={{marginBottom: '10px'}}>
                    Per iniziare, seleziona una settimana ed un esercizio. Per ciascun esercizio, avrai accesso ad una
                    chat con un tutor virtuale.
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    Scrivi il tuo messaggio nella chat, ed invialo cliccando il bottone '➤' o premendo 'Ctrl + Invio'
                    (o se hai un Mac: 'Cmd + Invio')
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    In ciascuna chat, il tutor ti aiuterà ad esercitare qualche concetto. Puoi chiedere spiegazioni,
                    esempi o aiuti di vario tipo.
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    L'obiettivo è fornire un'esperienza di apprendimento personalizzata: se ad esempio
                    l'argomento di un tema non ti piace o un esercizio è troppo difficile, prova a chiedere al tutor
                    di cambiare argomento, o di iniziare con qualcosa di piu semplice.
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    Gli esercizi non hanno un limite di tempo o un numero minimo di risposte che devi dare.
                    In generale, ti consigliamo di completare quello che il tutor ti richiede, ma non se non arrivi
                    alla fine della conversazione non è un problema.
                    Puoi tornare agli esercizi precedenti quando vuoi.
                  </li>
                </ul>
                <div className="home-problems">
                  <div><b>IN CASO DI PROBLEMI:</b></div>
                  <ul>
                    <li style={{marginBottom: '10px'}}>
                      Per chat particolarmente lunghe, o in qualche rara occasione, il tutor potrebbe confondersi o
                      deviare dall'esercizio originale. Se ti dovessi trovare in questa situazione, puoi cliccare
                      'Restart Chat'. Potrai vedere nella barra laterale, nella stessa settimana, una nuova chat per
                      lo stesso esercizio.
                    </li>
                    <li style={{marginBottom: '10px'}}>
                      Se dovessi avere qualunque tipo di problema relativo alla piattaforma o ai compiti per casa in
                      generale, oppure volessi interrompere l'esperimento, manda un messaggio su Whatsapp ad
                      Alessandro (+39 3471460931).
                    </li>
                    <li>
                      Può succedere che il tutor non dia informazioni accurate. Se sei in dubbio, chiedi chiarimenti alla tua insegnante.
                    </li>
                  </ul>
                </div>
              </div>
          ) : (
              <div>
                <div className="home-instructions">
                  <ul>
                    <li style={{marginBottom: '10px'}}>
                      In questa piattaforma puoi accedere ai compiti per casa di Inglese assegnati dalla tua
                      insegnante.
                    </li>
                    <li style={{marginBottom: '10px'}}>
                      L'obiettivo della piattaforma è testare delle nuove tecnologie per varie forme di apprendimento
                      personalizzato.
                      L'interfaccia e gli esercizi che vedi potrebbero essere diversi per altri tuoi compagni di
                      classe, e questo è normale.
                    </li>
                  </ul>
                  <div><b>ISTRUZIONI:</b></div>
                </div>
                <ul>
                  <li style={{marginBottom: '10px'}}>
                    Per iniziare, seleziona una settimana ed un esercizio. Potrai vedere l'esercizio che ti è stata assegnato.
                    Una volta risolto, potrai caricare la tua soluzione cilccando su 'Submit'.
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    Puoi tornare agli esercizi precedenti quando vuoi.
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    Le risposte possono essere modificate quando vuoi, anche dopo averle inviate.
                  </li>
                  <li style={{marginBottom: '10px'}}>
                    Come scrivi la soluzione di preciso non è importante, basta che si capisca.
                  </li>
                </ul>
                <div className="home-problems">
                  <div><b>IN CASO DI PROBLEMI:</b></div>
                  <ul>
                    <li style={{marginBottom: '10px'}}>
                      Se dovessi avere qualunque tipo di problema relativo alla piattaforma o ai compiti per casa in
                      generale, oppure volessi interrompere l'esperimento, rivolgiti alla tua insegnante o manda un messaggio su Whatsapp ad
                      Alessandro (+39 3471460931).
                    </li>
                  </ul>
                </div>
              </div>
          )}
        </div>
  );
};

export default Home;
