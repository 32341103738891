import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ExcelUpload from "./ExcelUpload";

const ClassCreationEdit = () => {
  // Load initial values from session storage or set to default
  const [classId, setClassId] = useState(sessionStorage.getItem('classId') || '');
  const [students, setStudents] = useState(JSON.parse(sessionStorage.getItem('students'))|| []);
  const [editMode, setEditMode] = useState(true);

  // Save to session storage whenever the classId changes
  useEffect(() => {
    sessionStorage.setItem('classId', classId);
  }, [classId]);

  // Save students to session storage whenever the students array changes
  useEffect(() => {
    sessionStorage.setItem('students', JSON.stringify(students));
  }, [students]);

  const handleValidExcel = (newStudents) => {
    const updatedStudents = [...students, ...newStudents];
    setStudents(updatedStudents);
  };

  const handleInputChange = (index, field, value) => {
    const updatedStudents = [...students];
    updatedStudents[index][field] = value;
    setStudents(updatedStudents);
  };

  const removeStudentRow = (index) => {
    const updatedStudents = students.filter((_, i) => i !== index);
    setStudents(updatedStudents);
  };

  const addStudentRow = () => {
    setStudents([...students, { name: '', surname: '', email: '' }]);
  };


  return (
      <div className="assignment-container">
        <form>

          <div className="input-group">
            <label>ClassId</label>
            <input type="text" value={classId} onChange={(e) => setClassId(e.target.value)} min="1" required/>
          </div>
        </form>
        <ExcelUpload onValidExcel={handleValidExcel}/>
        <div>
          <h2>Student List</h2>
          <table border="1" cellPadding="5">
            <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {students.map((student, index) => (
                <tr key={index}>
                  <td>
                    <input
                        type="text"
                        value={student.name}
                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        value={student.surname}
                        onChange={(e) => handleInputChange(index, 'surname', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        value={student.email}
                        onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                    />
                  </td>
                  <td onClick={() => removeStudentRow(index)} style={{ cursor: 'pointer' }}>❌</td>
                </tr>
            ))}
            <tr>
              <td colSpan="4" onClick={addStudentRow} style={{ cursor: 'pointer' }}>
                <u>Add student</u>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default ClassCreationEdit;