import Cookies from 'js-cookie';
import '../Home.css';
import authGet from "../../functions/authGet";
import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const ManageClasses = () => {
  const userName = Cookies.get('user_name');
  const userId = Cookies.get('user_id'); // Assuming user_id is stored in cookies
  const [classesData, setClassesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Hook for navigation

  const fetchClasses = async () => {
    try {
      const response = await authGet(`${serverUrl}/admin/all_classes`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setClassesData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchClasses();
  }, []);


  const handleClassOverview = (classId) => {
    navigate(`/admin/${classId}/overview`)
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
      <div className="home-content">
        <div>
          <h2 style={{ marginBottom: '15px' }}>Manage Classes</h2>
        </div>
        <div className="home-instructions">
          <ul>
            <li style={{ marginBottom: '10px' }}>
              Qui vedrai le tue classi.
            </li>
          </ul>
        </div>
        <div className="classes-container">
          {classesData.length === 0 ? (
              <p>No classes found</p>
          ) : (
              classesData.map((classItem) => (
                  <div key={classItem.class_id} className="bubble interactive" onClick={() => handleClassOverview(classItem.class_id)}>
                    <h3>Class ID: {classItem.class_id}</h3>
                    <p>Student count: {classItem.student_count}</p>
                  </div>
              ))
          )}
        </div>
      </div>
  );
};

export default ManageClasses;