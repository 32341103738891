// fields/PurposeField.js
import React, { useState } from 'react';

const PurposeField = ({ value, onChange, editMode, openModal }) => {
  const handleInfoClick = () => {
    openModal(
      <div>
        <h3>PURPOSE</h3>
        <p>
          In this part you write what you want the students to achieve/exercise
          on, during the assignment. <br />
          Some purposes example are:
        </p>
        <ul>
          <li>
            In case of an <u>essay</u>:
            <br />
            <i>
              Writing an argumentative essay on a contemporary social issue.
              <br />
              Students learn to express and argue in favor of a point and
              discuss counter arguments.
              <br />
              Students should learn proper sentence constructs such as on one
              hand, on the other hand, …
            </i>
          </li>
          <li>
            In case of <u>sentence correction</u>:
            <br />
            <i>
              Students should learn proper grammar constructs such as
              Conditionals.
              <br />
              Students should be trained to identify and correct common errors
              in grammar, syntax, and vocabulary.
              <br />
              Students should become more aware of their own weaknesses and
              their areas for improvement
            </i>
          </li>
          <li>
            In case of <u>sentence completion</u>:<br />
            <i>
              Students should learn proper grammar constructs such as
              Conditionals.
              <br />
              Students should reinforce sentence structure and word order.
              <br />
              Students should practice conjugating verbs.
              <br />
            </i>
          </li>
        </ul>
        <p>
          The topic, in other words, describes the "type" of exercise that you
          are going to do.
        </p>
      </div>
    );
  };
  return (
    <div className="bubble input">
      <div className="label-container">
        <label>Purpose</label>
        <button type="button" onClick={handleInfoClick}>
          ?
        </button>
      </div>
      {editMode ? (
        <div>
          <textarea
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              // Auto-resize logic
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            placeholder="What you want the students to learn"
          />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default PurposeField;
