import React from 'react';
import Cookies from "js-cookie";
import authGet from "../functions/authGet";
import { useNavigate } from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL;
const Logout = () => {
  const navigate = useNavigate();

  const logout = () => {
    authGet(serverUrl + '/logout')
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to logout on server side.');
          }
        })
        .catch(error => {
          console.error('Failed to logout on server side', error);
        })
        .finally(() => {
          sessionStorage.removeItem('logged_in')
          Cookies.remove('user_id');
          Cookies.remove('user_name');
          Cookies.remove('user_surname');
          Cookies.remove('token');
          navigate('/');
          window.location.reload()
        });
  };

  // Rest of your component
  return (
      <div>
      <button
          onClick={logout}
          style={{
            color: "white",
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            padding: '8px 16px', // You can adjust padding as needed
            fontSize: '16px', // You can adjust font size as needed
            marginTop: '10px',
            marginRight: 'auto',
            marginLeft: 'auto'
          }}
      >
        Logout
      </button>
      </div>
  );
};

export default Logout;
