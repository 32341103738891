import useFetch from "../../functions/useFetch";
import authGet from "../../functions/authGet";
import {useEffect, useState} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import Message from "../chat/Message";
import '../chat/Chat.css'


const serverUrl = process.env.REACT_APP_SERVER_URL;
const TeacherChatView = () => {
  const {chat_id} = useParams();
  const {data: chat, isPending, error} = useFetch(serverUrl + '/teacher/get_chat/' + chat_id);
  const [messages, setMessages] = useState();
  const navigate = useNavigate();


  // Use useEffect to update the messages state when messageList changes
  useEffect(() => {
    if (chat) {
      setMessages(chat.messages);
    }
  }, [chat]);


  return (
      <div className="chat">
        {isPending && <p>Loading ...</p>}
        {error && <p>{error}</p>}
        {chat &&
            <div style={{padding: '20px'}}>
              <h2>{chat.topic}</h2>
            </div>
        }
        {
            messages && messages.map((message, index) => (
                <Message key={message.id || index} message={message}/>
            ))
        }
      </div>
  );
}

export default TeacherChatView;