import React, {useState} from "react";

const ClassCreationConfirm = () => {
  const [students, setStudents] = useState(JSON.parse(sessionStorage.getItem('students'))|| []);
  const [classId, setClassId] = useState(sessionStorage.getItem('classId')|| '');

  return(
      <div>
        <h2>{classId}</h2>
        <h2>Student List</h2>
        <table border="1" cellPadding="5">
          <thead>
          <tr>
            <th>Name</th>
            <th>Name</th>
            <th>Surname</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody>
          {students.map((student, index) => (
              <tr key={index}>
                <td>{student.name}</td>
                <td>{student.surname}</td>
                <td>{student.email}</td>
              </tr>
          ))}
          </tbody>
        </table>
    </div>
  );
}

export default ClassCreationConfirm