import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClassCreationEdit from "./ClassCreationEdit";
import ClassCreationConfirm from "./ClassCreationConfirm";
import authPost from "../../functions/authPost";
import Sidebar from "../Sidebar";

const CreateClass = () => {
  const navigate = useNavigate();
  // Load initial values from session storage or set to default
  const [classId, setClassId] = useState(sessionStorage.getItem('classId') || '');
  const [students, setStudents] = useState(JSON.parse(sessionStorage.getItem('students'))|| []);
  const [editMode, setEditMode] = useState(true);

  // Save to session storage whenever the classId changes
  useEffect(() => {
    sessionStorage.setItem('classId', classId);
  }, [classId]);

  // Save students to session storage whenever the students array changes
  useEffect(() => {
    sessionStorage.setItem('students', JSON.stringify(students));
  }, [students]);


  const handleCreate = async () => {
    try {
      // Read the latest value of classId and students from sessionStorage
      const currentClassId = sessionStorage.getItem('classId') || '';
      const currentStudents = JSON.parse(sessionStorage.getItem('students')) || [];

      const classFields = {
        classId: currentClassId,
        students: currentStudents,
      };
      const response = await authPost(`/admin/create_class`, classFields);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result);

      // Handle successful response
      alert('Class created successfully!');

      // Clear session storage
      sessionStorage.removeItem('classId');
      sessionStorage.removeItem('students');

      setEditMode(false);
      navigate(`/admin/${classId}/overview`); // Adjust the path as per your routing setup
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create assignment. Please try again.');
    }
  };


  return (
      <div className="assignment-container">
        <div className="assignment-header">
          <h2>Class Creator</h2>
        </div>
        {editMode ? <ClassCreationEdit/> : <ClassCreationConfirm/>}
        <button onClick={() => setEditMode(!editMode)}>
          {editMode ? 'Confirm' : 'Edit'}
        </button>
        {!editMode &&
            <button onClick={handleCreate}>Create</button>
        }
      </div>
  );
};

export default CreateClass;