import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import authPost from '../../functions/authPost';
import authGet from '../../functions/authGet'; // Import authGet function
import TitleField from './assignments/TitleField';
import TopicField from './assignments/TopicField';
import PurposeField from './assignments/PurposeField';
import DescriptionField from './assignments/DescriptionField';
import ExampleField from './assignments/ExampleField';
import StrategyField from './assignments/StrategyField';
import './assignments/assignment.css';
import Modal from './assignments/Modal'; // Import the Modal component
import InfoModal from './assignments/InfoModal';
import useSessionState from '../../functions/useSessionState';

const CreateAssignment = () => {
  const { class_id } = useParams(); // Fetch class_id from the URL
  const [editMode, setEditMode] = useState(true);

  // Load initial values from session storage or set to default
  const [title, setTitle] = useSessionState('title', '');
  const [topic, setTopic] = useSessionState('topic', '');
  const [purpose, setPurpose] = useSessionState('purpose', '');
  const [description, setDescription] = useSessionState('description', '');
  const [example, setExample] = useSessionState('example', '');
  const [strategy, setStrategy] = useSessionState('strategy', '');
  const [week, setWeek] = useSessionState('week', '');
  const [number, setNumber] = useSessionState('number', '');

  const navigate = useNavigate();

  // Consolidated save to session storage whenever any relevant state changes
  useEffect(() => {
    sessionStorage.setItem('title', title);
    sessionStorage.setItem('topic', topic);
    sessionStorage.setItem('purpose', purpose);
    sessionStorage.setItem('description', description);
    sessionStorage.setItem('example', example);
    sessionStorage.setItem('strategy', strategy);
    sessionStorage.setItem('week', week);
    sessionStorage.setItem('number', number);
  }, [title, topic, purpose, description, example, strategy, week, number]);

  // Modal state
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const assignmentFields = {
      class_id,
      title,
      topic,
      purpose,
      description,
      example,
      strategy,
      week,
      number,
    };

    try {
      const response = await authPost(
        `/teacher/${class_id}/create_assignment`,
        assignmentFields
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      // Handle successful response
      alert('Assignment created successfully!');
      setEditMode(false);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create assignment. Please try again.');
    }
  };

  const handleGenerateStrategy = async () => {
    const assignmentFields = {
      purpose,
      description,
      example,
    };

    try {
      const response = await authPost(
        '/teacher/generate_strategy',
        assignmentFields
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setStrategy(result.strategy);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to generate strategy. Please try again.');
    }
  };

  const handleTryChat = async (e) => {
    e.preventDefault();
    const assignmentFields = {
      class_id,
      title,
      topic,
      purpose,
      description,
      example,
      strategy,
      week,
      number,
    };

    try {
      const response = await authPost(`/teacher/try-chat`, assignmentFields);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const newChatId = result.id;

      setEditMode(false);
      navigate(`/chat/${newChatId}?test-chat=True`); // Adjust the path as per your routing setup
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create assignment. Please try again.');
    }
  };

  return (
    <div className="assignment-container">
      <div className="assignment-header">
        <h2>Creating Assignment for class: {class_id}</h2>
      </div>
      <button type="button" onClick={() => openModal(<InfoModal />)}>
        When am I doing good?
      </button>

      <form onSubmit={handleSubmit}>
        {/* Week and Number fields */}
        <div className="input-row">
          <div className="input-group">
            <label>Week</label>
            <input
              type="number"
              value={week}
              onChange={(e) => setWeek(e.target.value)}
              min="1"
              required
            />
          </div>

          <div className="input-group">
            <label>Number</label>
            <input
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              min="1"
              required
            />
          </div>
        </div>

        {/* Field components with openModal prop */}
        <TitleField
          value={title}
          onChange={setTitle}
          editMode={editMode}
          openModal={openModal}
        />
        <TopicField
          value={topic}
          onChange={setTopic}
          editMode={editMode}
          openModal={openModal}
        />
        <PurposeField
          value={purpose}
          onChange={setPurpose}
          editMode={editMode}
          openModal={openModal}
        />
        <DescriptionField
          value={description}
          onChange={setDescription}
          editMode={editMode}
          openModal={openModal}
        />
        <ExampleField
          value={example}
          onChange={setExample}
          editMode={editMode}
          openModal={openModal}
        />
        <StrategyField
          value={strategy}
          onChange={setStrategy}
          editMode={editMode}
          onGenerateStrategy={handleGenerateStrategy}
          openModal={openModal}
        />

        <button type="button" onClick={() => setEditMode(!editMode)}>
          {editMode ? 'Confirm' : 'Edit'}
        </button>
        <div>
          {!editMode && (
            <div>
              <p>
                Please make sure that the information above is correct before
                submitting the assignment to the students.
              </p>
              <button type="button" onClick={handleTryChat}>
                Try Chat
              </button>
              <button type="submit">Submit Assignment</button>
            </div>
          )}
        </div>
      </form>
      {/* Include the Modal component */}
      <Modal isOpen={isModalOpen} content={modalContent} onClose={closeModal} />
    </div>
  );
};

export default CreateAssignment;
