import {Link} from "react-router-dom";

const AdminSidebar = () => {
  return (
      <div>
        <Link to='/admin/manage_classes'>
          <b>Manage Classes</b>
        </Link>
        <Link to='/create_class'>
          <b>Create New Class</b>
        </Link>
        <Link to='/admin/create_admin_teacher'>
          <b>Add New Teacher or Admin</b>
        </Link>
      </div>
  );
};

export default AdminSidebar;
