// fields/ExampleField.js
import React, { useState } from 'react';

const ExampleField = ({ value, onChange, editMode, openModal }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClick = () => {
    openModal(
      <div>
        <h3>EXAMPLE</h3>
        <p>
          Provide a short list of examples that match what you expect from the
          description.
          <br />
          According to the description you can choose as many examples as you
          want (i.e., for <u>sentence correction</u> you can give 2, 5, 10 or
          more sentences as you prefer):
        </p>
        <ul>
          <li>
            In case of an <u>essay</u>:<br />
            <i>
              Write an essay of 200-220 words on the risks of climate change.
            </i>
          </li>
          <li>
            In case of <u>sentence correction</u>:<br />
            <i>
              Correct the mistake in each sentence.
              <br />
              1. If I have known the truth, I would have helped you.
              <br />
              2. You would had been invited you hadn’t fought with everyone.
              <br />
              3. If I had saw her, I would have told her the situation.
              <br />
              4. Sheila wouldn’t have lose her money if she hadn’t spent
              everything on gambling.
            </i>
          </li>
          <li>
            In case of <u>sentence completion</u>:<br />
            <i>
              Complete the sentences with the correct tense of the verb in
              brackets
              <br />
              1. If he had studied harder, he ………………..(to pass) his English
              test.
              <br />
              2. If we get ready at 10:00 am we……………………… (have) a lot of time to
              arrange the meeting.
              <br />
              3. Lara would find the juice if she……………………. (look) carefully in
              the fridge.
              <br />
              4. The police would have given him a fine if he ……………………. (stop).
            </i>
          </li>
        </ul>
        <p></p>
      </div>
    );
  };
  return (
    <div className="bubble input">
      <div className="label-container">
        <label>Example</label>
        <button type="button" onClick={handleInfoClick}>
          ?
        </button>
      </div>
      {editMode ? (
        <div>
          <textarea
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              // Auto-resize logic
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            placeholder="Samples of exercise. See ? for more information"
          />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default ExampleField;
