import React, { useRef, useEffect } from 'react';

const StrategyField = ({
  value,
  onChange,
  editMode,
  onGenerateStrategy,
  openModal,
}) => {
  const textareaRef = useRef(null);

  const handleInfoClick = () => {
    openModal(
      <div>
        <h3>STRATEGY INFORMATION</h3>
        <p>
          The strategy is self-generated by pressing the "Generate strategy"
          button at the bottom of the page.
          <br />
          You <b>do not have to</b> write the strategy by yourself, but instead,
          you could eventually correct the one that is generated.
          <br />
          To understand what is the strategy there is no simple way, just try to
          press the button and see by yourself.
          <br /> <br />
          <center>
            It can take a few seconds to complete, <br />
            please do not press many times the button.
          </center>
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to handle shrink cases
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
  }, [value]); // Re-run on value change to adjust height

  return (
    <div className="bubble input">
      <div className="label-container">
        <label>Strategy</label>
        <button type="button" onClick={handleInfoClick}>
          ?
        </button>
      </div>
      {editMode ? (
        <div>
          <textarea
            ref={textareaRef}
            required
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="To be auto completed by pressing the button!"
            style={{
              overflow: 'hidden',
              resize: 'none',
              width: '90%',
              boxSizing: 'border-box', // Prevents horizontal overflow
              textAlign: 'justify',
            }}
          />
          <center>
            <button
              type="button"
              onClick={onGenerateStrategy}
              className="generate-strategy-button"
            >
              Generate Strategy
            </button>
          </center>
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default StrategyField;
