import Cookies from 'js-cookie';

const authGet = (url) => {
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${Cookies.get('user_token')}-${Cookies.get('user_id')}`,
    },
  });
};

export default authGet;
