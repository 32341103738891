const Message = ({message}) => {

  let header;
  if (message.type === 'agent') {
    header = 'Tutor 🦉';
  } else if (message.type === 'user') {
    header = 'User 📚';
  }


  return(
      <div className={`message ${message.type}`} key={message.id}>
        <div style={{ paddingBottom: '5px' }}><b>{header}</b></div>
        <p>{message.body}</p>
      </div>
  );
}

export default Message;