import authPost from "../../functions/authPost";

const NewAdminTeacher = () => {
  const handleCreateUser = async () => {
    try {
      // Collect form values
      const name = document.querySelector('input[name="name"]').value;
      const surname = document.querySelector('input[name="surname"]').value;
      const email = document.querySelector('input[name="email"]').value;
      const selectedRole = document.querySelector('input[name="role"]:checked');

      if (!selectedRole) {
        alert('Please select a role (Teacher or System Administrator).');
        return;
      }

      const roleValue = selectedRole.value;

      const userFields = {
        name,
        surname,
        email,
        admin: roleValue === 'admin',
        teacher: roleValue === 'teacher'
      };

      const response = await authPost(`/admin/add_teacher_or_admin`, userFields);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result);

      alert('User created successfully!');

      // Optionally reset form fields
      document.querySelector('form').reset();
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create user. Please try again.');
    }
  };

  return (
      <div style={{ maxWidth: '750px', textAlign: 'left', marginTop: '50px'}}>
        <h2>Create a New Teacher or System Administrator</h2>
        <p>In this page, you can create a new teacher or system administrator.</p>
        <ul>
          <li>
            <strong>Teacher:</strong> Can assign homework to their students. After creating a teacher, you can assign them to classes in the "Create Class" or "Manage Classes" page.
          </li>
          <li>
            <strong>System Administrator:</strong> Manages the system for all classes. Only select if needed.
          </li>
        </ul>
        <div className="bubble">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="bubble">
              <label>Name:</label>
              <input type="text" name="name" required />
            </div>
            <div className="bubble">
              <label>Surname:</label>
              <input type="text" name="surname" required />
            </div>
            <div className="bubble">
              <label>Email:</label>
              <input type="email" name="email" required />
            </div>
            <div className="bubble">
              <p>Select Role:</p>
              <label>
                <input type="radio" name="role" value="teacher" required /> Teacher
              </label>
              <label>
                <input type="radio" name="role" value="admin" required /> System Administrator
              </label>
            </div>
            <button type="submit" onClick={handleCreateUser}>Submit</button>
          </form>
        </div>
      </div>
  );
};

export default NewAdminTeacher;