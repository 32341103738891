// fields/InfoModal.js
import React from 'react';

const InfoModal = () => {
  return (
    <div>
      <h3>Understand what is important and what is not</h3>
      <p>
        Welcome to the assignment creation page of Minerva.
        <br />
        Here is where the magic happens. Understand what are you putting inside
        can lead to have better results for your studets.
        <br />
      </p>
    </div>
  );
};

export default InfoModal;
