import './App.css';
import Home from './components/Home'
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import LoginForm from "./components/LoginForm";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import Homework from "./components/Homework";
import Chat from "./components/chat/Chat";
import Sidebar from "./components/Sidebar";
import TeacherView from "./components/teacher/TeacherView";
import CreateAssignment from "./components/teacher/CreateAssignment";
import ClassOverview from "./components/teacher/ClassOverview";
import AssignmentOverview from "./components/teacher/AssignmentOverview";
import TeacherChatView from "./components/teacher/TeacherChatView";
import CreateClass from "./components/admin/CreateClass";
import AdminClassOverview from "./components/admin/AdminClassOverview";
import ManageClasses from "./components/admin/ManageClasses";
import NewAdminTeacher from "./components/admin/NewAdminTeacher";

function App() {

  const [authChecked, setAuthChecked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userGroup, setUserGroup] = useState(null);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    const loggedInSession = sessionStorage.getItem('logged_in') === 'true';
    const userIdCookie = Cookies.get('user_id');
    const tokenCookie = Cookies.get('token');


    if (loggedInSession) {
      setIsLoggedIn(true);
      setAuthChecked(true);
      setUserGroup(Cookies.get('user_group'));

    } else if (userIdCookie && tokenCookie) {
      try {
        const response = await fetch('/confirm_login', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenCookie
          },
        });

        if (response.ok) {
          setIsLoggedIn(true);
          sessionStorage.setItem('logged_in', 'true');
        }
      } catch (error) {
        console.error('Login confirmation error:', error);
      } finally {
        setAuthChecked(true);
      }
    } else {
      setAuthChecked(true);
    }
  };

  if (!authChecked) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
      <Router>
        <div className="App">
          {isLoggedIn && <Sidebar />}
          <div className="main">
            <Routes>
              <Route
                  exact
                  path="/"
                  element={isLoggedIn ? (
                      userGroup === 'teacher' ? <Navigate to="/teacher" replace /> : <Navigate to="/home" replace />
                  ) : (
                      <Navigate to="/login" replace />
                  )}              />
              <Route path="/home" element={<Home />}/>
              <Route path="/login" element={<LoginForm />}/>
              <Route path="/chat/:id" element={<Chat />}/>
              <Route path="/homework/:id" element={<Homework />}/>

              <Route path="/teacher" element={<TeacherView />}/>
              <Route path="/create-assignment/:class_id" element={<CreateAssignment />}/>
              <Route path="/teacher/:class_id/overview" element={<ClassOverview />}/>
              <Route path="/teacher/:class_id/:assignment_id/" element={<AssignmentOverview />}/>
              <Route path="/teacher/chat/:chat_id/" element={<TeacherChatView />}/>

              <Route path="/create_class/" element={<CreateClass />}/>
              <Route path="/admin/:class_id/overview" element={<AdminClassOverview />}/>
              <Route path="/admin/manage_classes" element={<ManageClasses />}/>
              <Route path="/admin/create_admin_teacher" element={<NewAdminTeacher/>}/>

              <Route path="*" element={<h1>The address you are looking for does not exist :(</h1>}/>
            </Routes>
          </div>
        </div>
      </Router>
  );
}

export default App;