// fields/TopicField.js
import React, { useState } from 'react';

const TopicField = ({ value, onChange, editMode, openModal }) => {
  const handleInfoClick = () => {
    openModal(
      <div>
        <h3>TOPIC</h3>
        <p>
          In this section, we provide the "Topic" of our assignments.
          <br />
          The topic is simply the title that the students will see on their
          chat.
          <br />
          Example of topic / tile can be:
        </p>
        <ul>
          <li>Conditionals / if clauses practice</li>
          <li>Essay on the Roman Empire</li>
          <li>Reading comprehension: Dorian Gray's life</li>
        </ul>
        <br />
        <p>
          This <u>will be the title that the Students will see on</u> above
          their chat.
        </p>
      </div>
    );
  };
  return (
    <div className="bubble input">
      <div className="label-container">
        <label>Long title (max 5-10 words)</label>
        <button type="button" onClick={handleInfoClick}>
          ?
        </button>
      </div>
      {editMode ? (
        <div>
          <textarea
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              // Auto-resize logic
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            placeholder="Title of the chat (will be seen by the students)"
          />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default TopicField;
