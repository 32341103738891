import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useFetch from "../functions/useFetch";
import Cookies from "js-cookie";
import './Homework.css'
import  { useRef } from 'react';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const Homework = () => {
  const {id} = useParams();
  const [answer, setAnswer] = useState('Loading... if after a few seconds you still see this message, please reload the page.')
  const [editMode, setEditMode] = useState(true)
  const {data: homework, isPending} = useFetch(serverUrl + '/homeworks/' + id);
  
  useEffect(() => {
    if (homework) {
      setAnswer(homework.answer_text);
      setEditMode(!homework.submitted);
    }
  }, [homework, homework?.submitted]); // Add homework?.submitted as a dependency


  function postAnswer(event) {
    event.preventDefault(); // This prevents the default form submission

    const answerData = {
      answer_text: answer // Use the answer state here
    };

    fetch(serverUrl + '/homeworks/' + id + '/post_answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${Cookies.get('token')}-${Cookies.get('user_id')}`,
      },
      body: JSON.stringify(answerData) // Stringify the answerData object
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          console.log('Submit successful');
          setEditMode(false);
        })
        .catch(error => {
          console.error('Error submitting solution... try again?', error);
        });
  }



  return (
      <div className="chat">
        {homework &&
            <div style={{padding: '20px'}}>
            <h2> {homework.topic}</h2>
            </div>
        }

        <div className='bubble assignment'>
          <b>Assignment:</b>
          <p>
            {homework && homework.assignment_text}
          </p>
        </div>

        {editMode && (
            <form onSubmit={postAnswer}>
            <div className='bubble input'>
              <textarea
                  required
                  value={answer} // Use value instead of body
                  onChange={(e) => {
                    setAnswer(e.target.value)
                    e.target.style.height = 'inherit';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
              />
            </div>
              {!isPending && <button type="submit">Submit solution</button>}
            </form>
        )}

        {!editMode &&
            <div>
               <div className='bubble answer'>
                <b>Your solution:</b>
                <p>
                  {answer && answer}
                </p>

              </div>
              <button
                  onClick={() => {setEditMode(true)}}
              >Edit solution</button>
            </div>
        }

      </div>
  )
}

export default Homework;