import React, { useState, useEffect } from 'react';

const ClassOverviewEdit = ({classData, setClassData}) => {
  const handleInputChange = (index, field, value) => {
    const updatedStudents = [...classData.students];
    updatedStudents[index][field] = value;
    setClassData({ ...classData, students: updatedStudents });
  };

  const removeStudentRow = (index) => {
    const updatedStudents = classData.students.filter((_, i) => i !== index);
    setClassData({ ...classData, students: updatedStudents });
  };

  const addStudentRow = () => {
    // setStudents([...students, { name: '', surname: '', email: '', user_group: 'new', id: ''}]);
    setClassData({ ...classData, students: [...classData.students, { name: '', surname: '', email: '', user_group: 'new', id: ''}] });
  };

  return (
      <div className="bubble">
        <div>
          <h2>Student List</h2>
          <table border="1" cellPadding="5">
            <thead>
            <tr>
              <th>Surname</th>
              <th>Name</th>
              <th>Email</th>
              <th>ID</th>
              <th>Group</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {classData.students.map((student, index) => (
                <tr key={index}>
                  <td>
                    <input
                        type="text"
                        value={student.surname}
                        onChange={(e) => handleInputChange(index, 'surname', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        value={student.name}
                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        value={student.email}
                        onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                    />
                  </td>
                  <td>{student.id}</td>
                  <td>{student.user_group}</td>
                  <td onClick={() => removeStudentRow(index)} style={{ cursor: 'pointer' }}>❌</td>

                </tr>
            ))}
            <tr>
              <td colSpan="6" onClick={addStudentRow} style={{ cursor: 'pointer' }}>
                <u>Add student</u>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
  );
};

export default ClassOverviewEdit;